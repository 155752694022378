/* eslint-disable @nx/enforce-module-boundaries */
import { ChevronDownIcon } from '@chakra-ui/icons';
import { ArrowDown, CloseIcon } from '@mybridge/icons';
import { Button, Checkbox, CheckboxGroup, Stack } from '@mybridge/ui';
import { forwardRef, useMemo, useState, useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FilterPopover } from 'v4/components/filter-popover';
import { useRadioGroup } from '@chakra-ui/react';
import { CustomRadioButton } from 'v4/components/custom-radio-checkbx';
import { SearchContext } from '../../../snippets/search/context';
import styles from '../../filter-popover/filter-popover.module.scss';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';
export const SearchFilterPostedBy = forwardRef(
  (
    {
      name = 'posted_by',
      title = 'Posted by',
      titleKey = 'searchPage.postedBy',
      onChange,
      value,
      defaultValue,
      ...props
    },
    ref
  ) => {
    const [selectedValue, setSelectedValue] = useState();
    const [popperOpen, setPopperOpen] = useState(false);
    const { resetFilters, setResetFilters } = useContext(SearchContext);
    const { t } = useTranslator();
    const values = useMemo(
      () => [
        {
          title: t('searchPage.firstConnections') || '1st connections',
          value: '1st_degree',
        },
        {
          title: t('searchPage.me') || 'Me',
          value: 'me',
        },
        {
          title: t('searchPage.peopleYouFollow') || 'People you follow',
          value: 'people_you_follow',
        },
      ],
      []
    );

    const { handleSubmit, control } = useForm();

    useEffect(() => {
      selectedValue ? setResetFilters(true) : setResetFilters(false)
    }, [selectedValue]);

    useEffect(() => {
      if(!resetFilters){
        setSelectedValue();
        // setSearch();
      }
    }, [resetFilters]);

    const onSubmit = (form) => {
      onChange?.({ [name]: form });
      setSelectedValue(form);
      setPopperOpen(false);
    };

    const { getRadioProps, getRootProps } = useRadioGroup({
      onChange: onSubmit,
    });

    return (
      <>
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <FilterPopover
          trigger={
            <Button
              variant="transparent"
              fontSize="15px"
              color="brandGray.750"
              rightIcon={selectedValue ? '' : ""}
              justifyContent="space-between"
              w="100%"
            >
               {selectedValue && resetFilters ? selectedValue : (t(titleKey) || title)}
                    {selectedValue && resetFilters ? <CloseIcon width="15" height="15" color="#3D5A80" cursor="pointer" onClick={() => onSubmit(null)} /> : <ArrowDown />}
            </Button>
          }
          onPopClose={(e) => setPopperOpen(e)}
        >
          <Controller
            render={({ field }) => (
              <CheckboxGroup {...field}>
                <Stack className={styles.filterList}>
                  {values?.map?.((v, vIndex) => (
                    // <Checkbox key={vIndex} value={v?.value}>
                    //   {v?.title}
                    // </Checkbox>
                    <CustomRadioButton
                      key={vIndex}
                      justifyContent="flex-start"
                      {...getRadioProps({ value: v?.value })}
                    >
                      {v?.title}
                    </CustomRadioButton>
                  ))}
                </Stack>
              </CheckboxGroup>
            )}
            control={control}
            name={name}
          />
        </FilterPopover>
        {/* </form> */}
      </>
    );
  }
);
