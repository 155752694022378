/* eslint-disable @nx/enforce-module-boundaries */
import { ChevronDownIcon } from '@chakra-ui/icons';
import { ArrowDown, CloseBlueIcon } from '@mybridge/icons';
import {
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Stack,
} from '@mybridge/ui';
import { forwardRef, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDisclosure } from '@mybridge/ui';
import { FilterPopoverPopper } from 'v4/components/filter-keyword-popover';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';
export const SearchFilterKeywords = forwardRef(
  ({ title = 'Keyword', titleKey = 'searchPage.keyword', onChange, value, defaultValue, ...props }, ref) => {
    const { handleSubmit, register, reset } = useForm();
    const { t } = useTranslator();
    const { onOpen, onClose, isOpen } = useDisclosure();

    const onSubmit = (form) => {
      console.log('form', form)
      console.log(form);
      onChange?.(form);
    };

    // useEffect(() => {
    //   if (!defaultValue) {
    //     reset();
    //   }
    // }, [defaultValue]);

    return (
      <>
        <form style={{width:"175px"}} onSubmit={handleSubmit(onSubmit)}>
          <FilterPopoverPopper
            placement="auto"
            trigger={
              <Button
                variant="transparent"
                fontSize="15px"
                color="brandGray.750"
                rightIcon={<ArrowDown />}
                justifyContent="space-between"
                w="100%"
                type="button"
              >
                {(t(titleKey) || title)}
              </Button>
            }
            withForm
            onPopClose={(e) => console.log()}
          >
            <Stack p={"20px"}>
              <Stack style={{display: 'flex', flexDirection: 'row',  justifyContent: 'space-between', alignItems: 'center'}}>
                <Heading size="md" fontWeight={400} color="#3D5A80">{(t(titleKey) || title)}</Heading>
              </Stack>
              <Stack direction={['column', 'row']}>
                <FormControl>
                  <FormLabel fontWeight={400}>{t('searchPage.firstName') || "First Name"}</FormLabel>
                  <Input style={{border: "1px solid #DDDDDD"}} placeholder={t('searchPage.firstName') || "First Name"} {...register('first_name')} />
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight={400}>{t('searchPage.lastName') || "Last Name"}</FormLabel>
                  <Input style={{border: "1px solid #DDDDDD"}} placeholder={t('searchPage.lastName') || "Last Name"} {...register('last_name')} />
                </FormControl>
              </Stack>
              <Stack direction={['column', 'row']}>
                <FormControl>
                  <FormLabel fontWeight={400}>{t('searchPage.title') || "Title"}</FormLabel>
                  <Input style={{border: "1px solid #DDDDDD"}} placeholder={t('searchPage.title') || "Title"} {...register('company_title')} />
                </FormControl>
              </Stack>
              <Stack direction={['column', 'row']}>
                <FormControl>
                  <FormLabel fontWeight={400}>{t('searchPage.currentCompany') || "Current Company"}</FormLabel>
                  <Input
                    placeholder={t('searchPage.currentCompany') || "Current Company"}
                    style={{border: "1px solid #DDDDDD"}}
                    {...register('current_company')}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight={400}>{t('searchPage.pastCompany') || "Past Company"}</FormLabel>
                  <Input
                    placeholder={t('searchPage.pastCompany') || "Past Company"}
                    style={{border: "1px solid #DDDDDD"}}
                    {...register('past_company')}
                  />
                </FormControl>
              </Stack>
              <Stack direction={['column', 'row']}>
                <FormControl>
                  <FormLabel fontWeight={400}>{t('education.school') || "School"}</FormLabel>
                  <Input placeholder={t('education.school') || "School"}  style={{border: "1px solid #DDDDDD"}} {...register('school')} />
                </FormControl>
              </Stack>
            </Stack>
          </FilterPopoverPopper>
        </form>
      </>
    );
  }
);
